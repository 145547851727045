html, body{
  scroll-behavior: smooth;

}

body{
  height: 100vh;
  width: 100vw;

  overflow-y: auto;
}

.App {
  text-align: center;
  background-color: white;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.canvas{
    width: 100vw;
    text-align: center;
    overflow-x: hidden;
    display: block;
}

.xincuntext{
  font-family: 'Lora', serif;
  font-size: 1rem;
}

.box{
  width: 100%;
  height: 640px;
}

.projecttitle{
  width: 82%;
}

#xincunphoto{
  width: 90%;
  position: absolute;
  top: 37.3%;
}

#installationphoto{
  align-items: center;
}

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .writing{
    width: 135vw;
    transform: translateX(-13%);
  }

  #drag{
    display: none;
  }

  #welcome{
    display: none;
  }

  #welcome2{
    display: none;
  }

  #nostalgia{
    display: none;
  }

  #techdetermin{
    display: none;
  }

  #techinchina{
    display: none;
  }

  #media{
    display: none;
  }

  #xincun{
    display: none;
  }

  /* buttons */
  #down{
    height: 6vh;
  }

  #question{
    width: 3vw;
    height: 2vh;
    /* background-color: grey; */
    top: 26.9%;
    left: 55%;
  }

  .link{
    font-size: 2.3vw;
  }

  #artist{
    top: 26.6%;
    left: 57%;
    width: 80vw;
    display: none;
  }

  #imalink{
    top: 29.4%;
    left: 55.9%;
    width: 27vw;
    height: 0.8%;
  }

  #linkedin{
    top: 77.6%;
    left: 37%;
    width: 23vw;
    height: 1%;
  }

  #email{
    top: 77.6%;
    left: 65.6%;
    width: 12.5vw;
    height: 1%;
  }

  #insta{
    top: 79.4%;
    left: 48.7%;
    width: 31vw;
    height: 1%;
  }

  /* content */
  #xincunphoto{
    width: 100%;
  }

  .projects{
    width: 100%;
    position: absolute;
    top: 52%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  .projectphoto{
    width: 80%;
    margin: 0 auto;
    margin-top: 5px;
    /* max-width: 780px; */
    /* top: 56%; */
    border: 5px solid grey;
    display: block;
  }

  .projectmore{
    margin-bottom: 10px;
    width: 90%;
  }
}

/* Medium devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .writing{
    width: 120vw;
    transform: translateX(-8%);
  }

  #drag{
    display: none;
  }

  #welcome{
    top: 0;
    left: 1%;
    cursor: move;
  }

  #welcome2{
    display: none;
  }

  #nostalgia{
    display: none;
  }

  #techdetermin{
    display: none;
  }

  #techinchina{
    display: none;
  }

  #media{
    display: none;
  }

  #xincun{
    display: none;
  }
  /* buttons */
  #down{
    height: 8vh;
  }

  #question{
    width: 3.5vw;
    height: 2vh;
    /* background-color: grey; */
    top: 26.9%;
    left: 54%;
  }

  #artist{
    top: 26.8%;
    left: 56.5%;
    width: 60vw;
    display: none;
  }

  .link{
    font-size: 2.2vw;
  }

  #imalink{
    top: 29.4%;
    left: 55.5%;
    width: 24.5%;
    height: 0.8%;
  }

  #linkedin{
    top: 77%;
    left: 37%;
    width: 22vw;
    height: 1.2%;
  }

  #email{
    top: 77%;
    left: 65.3%;
    width: 11.5vw;
    height: 1.2%;
  }

  #insta{
    top: 79%;
    left: 48.7%;
    width: 28vw;
    height: 1.2%;
  }

  /* content */
  #xincunphoto{
    width: 90%;
  }

  .projects{
    width: 90%;
    position: absolute;
    top: 52%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  .projectphoto{
    width: 80%;
    margin: 0 auto;
    margin-top: 5px;
    /* max-width: 780px; */
    /* top: 56%; */
    border: 5px solid grey;
    display: block;
  }

  .projectmore{
    margin-bottom: 10px;
    width: 90%;
  }
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .writing{
    width: 90vw;
    transform: translateX(0);
  }

  .box{
    height: 550px;
  }

  #drag{
    display: block;
    top: 18%;
    right: 1%;
    width: 50%;
  }

  #welcome2{
    display: block;
    top: 7%;
    left: 1%;
    cursor: move;
  }

  #nostalgia{
    display: block;
    top: 53%;
    right: 0;
    cursor: move;
  }

  #techdetermin{
    display: block;
    top: 55%;
    left: 0;
    cursor: move;
  }

  #techinchina{
    display: block;
    top: 47%;
    left: 0;
    cursor: move;
  }

  #nostalgiaimage{
    width: 80%;
  }

  #techimage{
    width: 75%;
  }

  #media{
    display: block;
    top: 57.4%;
    left: 2%;
    cursor: move;
  }

  #xincun{
    display: block;
    top: 83%;
    right: 1%;
    cursor: move;
  }
  /* buttons */
  #down{
    height: 10vh;
  }

  .link{
    font-size: 1.6vw;
  }

  #question{
    width: 3vw;
    height: 2.5vh;
    /* background-color: grey; */
    top: 26.9%;
    left: 53%;
  }

  #artist{
    top: 26.6%;
    left: 55%;
    width: 45vw;
    display: none;
  }

  #imalink{
    top: 29.4%;
    left: 54%;
    width: 18%;
    height: 0.8%;
  }

  #linkedin{
    top: 77.1%;
    left: 37%;
    width: 16vw;
    height: 1.2%;
  }

  #email{
    top: 77.1%;
    left: 65.2%;
    width: 9vw;
    height: 1.2%;
  }

  #insta{
    top: 79%;
    left: 48.7%;
    width: 21vw;
    height: 1.2%;
  }

  /* content */
  #xincunphoto{
    width: 66%;
  }

  .projects{
    width: 66%;
    position: absolute;
    top: 52%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  .projectphoto{
    width: 80%;
    margin: 0 auto;
    margin-top: 5px;
    /* max-width: 780px; */
    /* top: 56%; */
    border: 5px solid grey;
    display: block;
  }

  .projectmore{
    margin-bottom: 10px;
    width: 90%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .box{
    height: 640px;
  }

  .writing{
    width: 90vw;
  }

  #welcome2{
    top: 8%;
    left: 1%;
    cursor: move;
  }

  #drag{
    top: 18%;
    right: 1%;
    width: 55%;
  }

  #nostalgia{
    right: 2%;
  }

  #techdetermin{
    left: 1.5%;
  }

  #techinchina{
    left: 1%;
  }

  #nostalgiaimage{
    width: 100%;
  }

  #techimage{
    width: 90%;
  }
  /* buttons */
  #down{
    height: 15vh;
  }

  .link{
    font-size: 1.6vw;
  }

  #question{
    width: 3vw;
    height: 3.2vh;
    /* background-color: grey; */
    top: 26.9%;
    left: 53%;
  }

  #artist{
    top: 26.6%;
    left: 55%;
    width: 45vw;
    display: none;
  }

  #imalink{
    top: 29.4%;
    left: 54%;
    width: 18%;
    height: 0.8%;
  }

  #linkedin{
    top: 77.1%;
    left: 37%;
    width: 16vw;
    height: 1.2%;
  }

  #email{
    top: 77.1%;
    left: 65.2%;
    width: 9vw;
    height: 1.2%;
  }

  #insta{
    top: 79%;
    left: 48.7%;
    width: 21vw;
    height: 1.2%;
  }

 /* content */
 #xincunphoto{
   width: 68%;
 }

  .projects{
    width: 68%;
    position: absolute;
    top: 52%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }

  .projectphoto{
    width: 80%;
    margin: 0 auto;
    margin-top: 5px;
    /* max-width: 780px; */
    /* top: 56%; */
    border: 5px solid grey;
    display: block;
  }

  .projectmore{
    margin-bottom: 5px;
    width: 90%;
  }
}

@media only screen and (min-width: 1600px) {
  #drag{
    top: 18%;
    right: 1%;
    width: 50%;
  }

  #welcome2{
    top: 8%;
    left: 1%;
    cursor: move;
  }

  #techimage{
    width: 100%;
  }

  #linkedin{
    top: 76.3%;
    /* top: calc(92.2 -9vw/1450); */
    left: 37%;
    width: 16vw;
    height: 1.2%;
  }

  #email{
    top: 76.3%;
    left: 65.4%;
    width: 9vw;
    height: 1.2%;
  }

  #insta{
    top: 78.3%;
    left: 48.7%;
    width: 21vw;
    height: 1.2%;
  }
}

@media only screen and (min-width: 1700px) {
  #linkedin{
    top: 76%;
  }

  #email{
    top: 76%;
  }

  #insta{
    top: 78%;
  }
}
/* XXL */
@media only screen and (min-width: 1870px) {

  #drag{
    top: 18%;
    right: 1%;
    width: 40%;
  }

  #linkedin{
    top: 74.5%;
  }

  #email{
    top: 74.5%;
  }

  #insta{
    top: 76.7%;
  }
}

/* universal */

/* buttons */
.hover{
  position: absolute;
}

#drag{
  transition: 5s;
}

#down{
  width: 10vw;
  /* background-color: grey; */
  top: 15%;
  left: 45%;
}

#down:hover{
  cursor: s-resize;
}


#question:hover{
  cursor: help;
}

.link{
  background-color: hotpink;
  opacity: 0.3;
  color: grey;
  font-style: italic;
  transition: 1s;
}

.link:hover{
  opacity: 0;
  transition: 1s;
}

a{
  text-decoration: none;
  color: grey;
  cursor: alias;
}

#holotitle{
  margin-bottom: 0;
}

/* draggable */
#welcome{
  top: 0;
  left: 1%;
  cursor: move;
}

#read{
  top: 30%;
  right: 1%;
  cursor: move;
}

/* hover content */

 /* #ending{
  margin-top: 24px;
} */
