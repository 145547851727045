.project{
  position: relative;
}

#bg{
  height: 90vh;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .text{
    width:75%;
    margin-top: 7%;
  }

  .dragtitle{
    display: none;
  }

  #back{
    width: 35%;
  }

  #top{
    width: 35%;
  }
}

/* Medium devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .text{
    width: 70%;
  }

  /* .dragtitle{
    display: none;
  } */

  #back{
    width: 20%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .text{
    width: 50%;
  }

  #back{
    width: 15%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .text{
    width: 50%;
  }

  #back{
    width: 15%;
  }
}

#back{
  position: fixed;
  left: 5%;
  top: 2%;
  z-index: 10;
}

.dragtitle{
  right: 1%;
  top: 1%;
  cursor: move;
  z-index: 10;
}

.text{
  position: absolute;
  text-align: left;
  top: 2%;
  padding: 5%;
  color: black;
}

h4{
  margin-bottom: 10px;
}

.prophoto{
  width: 100%;
}
.embed-container { position: relative; padding-bottom: 60%; height: 0; overflow: hidden; max-width: 90%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
